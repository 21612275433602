import { useStore } from '@/store/index.js'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook('app:created', () => {
    const { fetchArticles, fetchTags } = useStore()
    return Promise.all([
      fetchArticles(),
      fetchTags()
    ])
  })
})