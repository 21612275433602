import { default as _91slug_93NttmzUCULLMeta } from "/builds/messagenet/lyber-blog/pages/blog/articles/[slug].vue?macro=true";
import { default as indexuTmCDFyDonMeta } from "/builds/messagenet/lyber-blog/pages/blog/index.vue?macro=true";
import { default as _91slug_93VDCnxBeimqMeta } from "/builds/messagenet/lyber-blog/pages/blog/tags/[slug].vue?macro=true";
export default [
  {
    name: "article",
    path: "/blog/articles/:slug()",
    meta: _91slug_93NttmzUCULLMeta || {},
    component: () => import("/builds/messagenet/lyber-blog/pages/blog/articles/[slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/builds/messagenet/lyber-blog/pages/blog/index.vue")
  },
  {
    name: "blog-tags-slug",
    path: "/blog/tags/:slug()",
    component: () => import("/builds/messagenet/lyber-blog/pages/blog/tags/[slug].vue")
  }
]