import { defineStore } from 'pinia'
import articlesQuery from '@/apollo/queries/article/articles.gql'
import tagsQuery from '@/apollo/queries/tag/tags.gql'

export const useStore = defineStore({
  id: 'blog-lyber-store',
  state: () => {
    return {
      articles: [],
      tags: []
    }
  },
  getters: {
    articleBySlug: (state) => {
      return (slug) => state.articles?.find(a => a.attributes.slug === slug)
    }
  },
  actions: {
    async fetchArticles () {
      const { data } = await useAsyncQuery(articlesQuery)
      this.articles = data.value?.articles?.data
    },
    async fetchTags () {
      const { data } = await useAsyncQuery(tagsQuery)
      this.tags = data.value?.tags?.data
    }
  }
})